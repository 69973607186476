import React, { useEffect, useState } from "react";
import "./CaseStudyContent.css";
import Nutrition from "../images/Nutrition.png";
import Fizo from "../images/Fizo.png";
import Dudhbhate from "../images/Dudhbhate.png";
import LMS from "../images/LMS.png";
import Audio from "../images/Audio.png";
import { LineAnimation } from "../../LineAinimation/LineAnimation";

import { useContext } from "react";
import { Context } from "../../utils/context";


const CaseStudyContent = () => {

  const { getData, imageUrl } = useContext(Context);
  const [getcaseData, setCasedata] = useState();

  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const getcase = async () => {

    const res = await getData(`/casetitle`);

    if (res?.success) {
      console.log(res.data.lists);
      setCasedata(res.data.lists);
    } else {

    }
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    getcase();
  }, []);


  return (
    <>
      <section className="CaseStudy">

        <div className="container">

          <LineAnimation />

          <div className="main-navbar">
            <ul
              class="nav nav-pills mb-3 justify-content-center"
              id="pills-tab"
              role="tablist"
            >   {getcaseData?.map((data, index) => (
              <li class="nav-item" role="presentation">
                <button
                  class={index === 0 ? "nav-link active" : "nav-link"}
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target={`#pills-home${data?.id}`}
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  {data?.title}
                </button>
              </li>
            ))}

            </ul>


            {getcaseData?.map((data, index) => (
              <div>
                <div class="tab-content" id="pills-tabContent">
                  {/* 1st tab */}
                  <div
                    class={index === 0 ? "tab-pane fade show active" : "tab-pane fade"}
                    id={`pills-home${data?.id}`}
                    aria-labelledby="pills-home-tab"
                    tabindex="0"
                  >
                    {data?.case2?.map((type, index) => (
                      <>
                        {type?.status == 1 ? (
                          <div className="row pb-5">
                            {index % 2 === 0 ? (
                              <>
                                <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-8">
                                  <div className="Circle">
                                    <div className="nutrition">
                                      <img key={type?.id} src={imageUrl + type?.image} className="nutri" alt="this is image" />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-12 my-auto">
                                  <div className="content">
                                    <h4>{type?.title}</h4>
                                    <p>
                                      {htmlToReactParser.parse(type?.description)}
                                    </p>
                                    <div className="talk">
                                    <button type="button" className="btn btn-talk" data-bs-toggle="offcanvas" data-bs-target="#contact_off-canvas"
                                            aria-controls="offcanvasTop"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Talk to your experts</span></button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="col-xxl-8 col-xl-8 col-lg-7 col-md-12 my-auto">
                                  <div className="content">
                                    <h4>{type?.title}</h4>
                                    <p>
                                      <span>{htmlToReactParser.parse(type?.description)}</span>
                                    </p>
                                    <div className="talk">
                                      {/* <button type="button" class="btn btn-talk">
                                        Talk to your experts
                                      </button> */}
                                       <button type="button" className="btn btn-talk" data-bs-toggle="offcanvas" data-bs-target="#contact_off-canvas"
                                            aria-controls="offcanvasTop"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Talk to your experts</span></button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xxl-4 col-xl-4 col-lg-5 col-md-8">
                                  <div className="Circle">
                                    <div className="nutrition">
                                      <img key={type?.id} src={imageUrl + type?.image} className="nutri" alt="this is image" />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        ) : ""}
                      </>
                    ))}
                  </div>

                  {/* Other tabs */}
                  <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
                    ...
                  </div>
                  <div class="tab-pane fade" id="pills-disabled" role="tabpanel" aria-labelledby="pills-disabled-tab" tabindex="0">
                    ...
                  </div>
                </div>
              </div>
            ))}

          </div>



        </div>

      </section>
    </>
  );
};

export default CaseStudyContent;
